module services {
    export module applicationcore {
        export class unLocationCodeService implements interfaces.applicationcore.IUNLocationCodeService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "UNLocationCode/GetDropdownList", {
                    countryId: '@countryId',
                    searchText: "@searchText",
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            getDropdownListAllCountries(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "UNLocationCode/GetDropdownListAllCountries", {
                    searchText: "@searchText",
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }
        }
    }
    angular.module("app").service("unLocationCodeService", services.applicationcore.unLocationCodeService);
}